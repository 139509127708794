<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Create Voucher</h3>
    </div>

    <!-- Content Row -->
    <VoucherForm :voucher-id="$route.params.id"></VoucherForm>

  </div>
</template>

<script>
import VoucherForm from "../../../../components/Forms/VoucherForm";
export default {
  name: "Create",
  components: {VoucherForm}
}
</script>

<style scoped>

</style>